import React from "react"
import Styles from "../../styles/components/OurValues.module.scss"

const OurValues = () => (
  <div className={`${Styles.container} container-padding`}>
    <div className={Styles["section-line--title"]}>
      <h5>Our Values</h5>
    </div>
    <div className={Styles["section-container"]}>
      <div>
        <h3>Empathy</h3>
        <p>
          We always try to see things from the clients perspective, what is problem we're trying to solve, why is this importance, do we believe we can exceed expections. Would this make a difference and lead the organization forward. We're here to support not just work.
        </p>
      </div>
      <div>
        <h3>Excellence</h3>
        <p>
          We believe the cornerstone of innovation is excellence, innovation, impact and transformation have no regard for mediocrity and we don't tolerate it. We strive for excellence and show same attitude as we handle client's projects.
        </p>
      </div>
      <div>
        <h3>Joy</h3>
        <p>
          We belive work should be joyful, we believe an atmosphere of joy at work would influence the results of the project and we therefore strive to maintain a joyful spirit. There'll always be pressure but with joy, you always come out on top.
        </p>
      </div>
      <div>
        <h3>Focus</h3>
        <p>
          When you have just one goal, one thing you have decided to attain, everything begins to speak to you on how to attain it, we understand the law of focus. Don't do too many things at a time, don't give yourself to one problem at a time.
        </p>
      </div>
      <div>
        <h3>Discipline</h3>
        <p>
          No other value can be achieved without discipline, this is the one piece that holds all together. Discipline to us means the ability to stay mission minded. To give yourself to the task at hand and ensure its completion in due time.
        </p>
      </div>
      <div>
        <h3>Growth</h3>
        <p>
          We believe we always have to be at the cutting edge of the field we've dedicated ourselves to, this means we must continuous learn, unlearn and relearn. It is our desire and pursuit of growth that keep us relevant and gives us a seat at the table.
        </p>
      </div>
    </div>
  </div>
)

export default OurValues
