import React from "react"
import Styles from "../../styles/components/OurTeam.module.scss"

const OurTeam = () => (
  <div className="container-padding">
    <div className={Styles["section-line--title"]}>
      <h5>Our Team</h5>
    </div>
    <div className={Styles.box}>
      <div className={Styles.card}>
        <div className={Styles.imgBx}>
          <img src="/david_small.jpg" alt="images" />
        </div>
        <div className={Styles.details}>
          <h2>
            David Ubanyi
            <br />
            <span>CEO</span>
          </h2>
        </div>
      </div>

      <div className={Styles.card}>
        <div className={Styles.imgBx}>
          <img src="/abro_small.jpg" alt="images" />
        </div>
        <div className={Styles.details}>
          <h2>
            Omadoye Abraham
            <br />
            <span>CTO</span>
          </h2>
        </div>
      </div>

      <div className={Styles.card}>
        <div className={Styles.imgBx}>
          <img src="/barnabas_small.jpg" alt="images" />
        </div>
        <div className={Styles.details}>
          <h2>
            Barnabas Emwemasor
            <br />
            <span>Developer</span>
          </h2>
        </div>
      </div>

      <div className={Styles.card}>
        <div className={Styles.imgBx}>
          <img src="/marvelous_small.jpeg" alt="images" />
        </div>
        <div className={Styles.details}>
          <h2>
            Marvelous Olaniyan
            <br />
            <span>Developer</span>
          </h2>
        </div>
      </div>
      <div className={Styles.card}>
        <div className={Styles.imgBx}>
          <img src="/uwa_small.jpg" alt="images" />
        </div>
        <div className={Styles.details}>
          <h2>
            Uwa Uwoghiren
            <br />
            <span>Designer</span>
          </h2>
        </div>
      </div>
      <div className={Styles.card}>
        <div className={Styles.imgBx}>
          <img src="/blessing_small.jpg" alt="images" />
        </div>
        <div className={Styles.details}>
          <h2>
            Blessing Adeleye
            <br />
            <span>Interaction Designer</span>
          </h2>
        </div>
      </div>
    </div>
  </div>
)

export default OurTeam
