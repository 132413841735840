import React from "react"
import Styles from "../../styles/components/OurStory.module.scss"
import Pic from "../../images/about-us-pic.jpg"
import ScrollAnimation from "react-animate-on-scroll"

const HomeSectionOne = () => (
  <div
    className={`${Styles["background"]} ${
      Styles["section-container"]
    } container-padding`}
  >
    <div>
      <div className={Styles["section-line--title"]}>
        <h5>Our Story</h5>
      </div>
      <div className={Styles["section-divider"]}>
        <div className={Styles["section-divider--text"]}>
          <h1 className={Styles["section-title--big"]}>
            The Collective
          </h1>
          <ScrollAnimation animateIn="fadeIn">
            <div className={Styles["section-text--divider"]}>
              <p>
                It occured to me one day in 2016 that if I could help businesses perform better in areas of revenue and operations by driving digital innovation then small businesses could become big businesses, big businesses could become bigger. I thought about who benefited and the answer was clear? EVERYONE!! This was the drive that led to the starting of the company and since then we've helped tens of businesses innovate, grow and lead their spaces.
                <br/>
                <br/>
                We've worked with local and international organizations on various projects with this same mindset and drive. We're looking forward to working with you too.
              </p>
            </div>
          </ScrollAnimation>
        </div>
        <div className={Styles['story-pic-container']}>
        <ScrollAnimation animateIn="zoomInDown">
          <img className={Styles['story-pic']} src={Pic} alt="venn diagram" />
        </ScrollAnimation>
        </div>
      </div>
    </div>
  </div>
)

export default HomeSectionOne
