import React from "react"
import PageHero from "../components/pagehero"
import Footer from "../components/footer"
import OurStorySection from "../components/AboutPage/OurStory"
import OurValuesSection from "../components/AboutPage/OurValues"
import OurTeamSection from "../components/AboutPage/OurTeam"
import CTASection from "../components/cta"
import MobileHeader from "../components/MobileHeader"

const About = () => (
  <div>
    <MobileHeader></MobileHeader>
    <PageHero
      heading={["Why do we care? It's simple, ", <span key="about">The better your organization the better the world</span>]}
      description="Solving your problems means you perform better, which empowers the next guy to perform better and the next guy, the pattern continues"
      imgSrc="/about-us.jpg"
      pageTitle="About Us"
    ></PageHero>
    <OurStorySection></OurStorySection>
    <OurValuesSection></OurValuesSection>
    <OurTeamSection></OurTeamSection>
    <CTASection></CTASection>
    <Footer></Footer>
  </div>
)

export default About
