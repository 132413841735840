import React from "react"
import PhosLogo from "../images/phoslogo.svg"
import NavStyles from "../styles/components/TopNav.module.scss"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Header = () => (
  <div>
    <div className={NavStyles.logo}>
      {" "}
      <AniLink cover to="/" direction="right" bg="rgba(0, 0, 0)" duration={0.3}>
        <img src={PhosLogo} alt="PhosMobile Logo" />
      </AniLink>
    </div>
    <div className={`${NavStyles["mobile--header"]}`}>
      <div className={NavStyles.mobileContainer}>
        <AniLink to="/" activeClassName="mobileActive">
          <h4>Home</h4>
        </AniLink>
        <AniLink to="/case-studies" activeClassName="mobileActive">
          <h4>Work</h4>
        </AniLink>
        <AniLink to="/about/" activeClassName="mobileActive">
          <h4>About</h4>
        </AniLink>
        <AniLink to="/expertise/" activeClassName="mobileActive">
          <h4>Expertise</h4>
        </AniLink>
        <AniLink
          to="/contact/"
          className={NavStyles.contact}
          activeClassName="mobileActive"
        >
          <h4>Contact</h4>
        </AniLink>
      </div>
    </div>
  </div>
)

export default Header
