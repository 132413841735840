import React from "react"
import { Link } from "gatsby"
import Styles from "../styles/components/CTA.module.scss"
import ScrollAnimation from "react-animate-on-scroll"
import CtaButton from './ctaButton'

const CTA = () => (
  <div className={`${Styles.container} container-padding`}>
    <div className={Styles["intro-text"]}>We'll love to get to know you!</div>
    <ScrollAnimation animateIn="bounceIn">
      <CtaButton buttonTitle="Say Hi" buttonUrl="contact"></CtaButton>
    </ScrollAnimation>
  </div>
)

export default CTA
